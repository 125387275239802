<script setup lang="ts">
import { onMounted } from "vue"
import { initialize, initFreshChat } from "@/lib/component/freshworks"
import { useCookieStore } from "@/store"

const { analyticDataLayer } = useCookieStore()

const anyWindow = window as any
const isChatAvailable = analyticDataLayer.value.is_chat_available

onMounted(() => {
  if (isChatAvailable) {
    initialize(document, "Freshdesk Messaging-js-sdk", () => initFreshChat(anyWindow))
  }
})
</script>

<template>
  <v-container v-if="!isChatAvailable" id="freshworks-container">
    <v-row>
      <v-col class="justify-center align-center">
        <router-link id="freshworks-router-link" :to="{ name: 'faq' }">
          <v-container class="button-container">
            <v-row justify="center" align="center">
              <v-col class="col-1">
                <img src="~@/assets/images/svg/bulb_white.svg" alt="bulb_white" class="bulb-image init-img raise-items" />
                <img src="~@/assets/images/svg/bulb_green.svg" alt="bulb_white" class="bulb-image hover-img raise-items" />
              </v-col>
              <v-col class="col-9">
                <p class="raise-items">{{ $t("faqButtonName") }}</p>
              </v-col>
            </v-row>
          </v-container>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.bulb-image {
  width: 23px;
  height: 21px;
}

.raise-items {
  margin-bottom: 0.3em
}

#freshworks-router-link {
  .hover-img {
    display: none;
    width: 23px;
    height: 21px;

    margin-top: 0.3em;

    transition: 0.25s;
  }

  .init-img {
    display: inline-block;
    width: 23px;
    height: 21px;

    margin-top: 0.3em;

    transition: 0.25s;
  }

  .button-container {
    max-width: 80%;
    max-height: 90%;

    margin-left: auto;

    border: solid 2px white;
    border-radius: 5px;

    color: $simplic-white;
    font-weight: bold;

    transition: 0.25s;
  }

  &:hover {
    .hover-img {
      display: inline-block;
    }

    .init-img {
      display: none;
    }

    .button-container {
      cursor: pointer;

      background-color: $simplic-white;
      color: $simplic-green;
    }
  }
}


a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
}
</style>
<i18n>
{
  "pt": {
    "faqButtonName": "Tire suas dúvidas"
  }
}
</i18n>
