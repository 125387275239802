/**
 * initialize creates the freshworks chat button on the webpage
 * @param document The page document
 * @param elementID The ID of the element the freshworks chat button will be appended to
 * @param initFreshChat A method to initialize the freshworks chat button
 */
const initialize = (document: any, elementID: string, initFreshChat: () => void) => {

  if (document.getElementById(elementID)) {
    initFreshChat()
  } else {
    const element: HTMLScriptElement = document.createElement("script")
    element.id = elementID
    element.async = !0
    element.src = "https://wchat.freshchat.com/js/widget.js"
    element.onload = initFreshChat
    document.head.appendChild(element)
  }
}

/**
 * initFreshChat initializes the Freshworks widget in the window.
 * @param window The window
 */
const initFreshChat = (window: any) => {
  window.fcWidget.init({
    token: process.env.VUE_APP_FRESHWORKS_TOKEN,
    host: process.env.VUE_APP_FRESHWORKS_URL
  })
}

export { initialize, initFreshChat }
